import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../config/routes';

const PasswordForgetPage = () => (
    
    <div className="full-height-container" style={{backgroundColor:'#FFFFFF'}}>
    <div className="container both-center">
        <div className="text-center pb-4">
            <span>
            <img src="/images/logo-mercate.png" className="img-fluid my-auto" alt="..." style={{height:'80px'}}/> 
            </span>
        </div>
        <div className="row">
            <div className="col col-10 mx-auto card-title text-center">
                <h3>Esqueci minha senha</h3>
            </div>
        </div>
        <div className="row">
            <div className="col col-10 mx-auto card-title">
                <PasswordForgetForm />
            </div>
        </div>
    </div>    
    </div>
);

const INITIAL_STATE = {
    email:'',
    error:null
};

class PasswordForgetFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault()
        const { email } = this.state;
        this.props.firebase
            .doPasswordReset(email)
            .then(()=>{
                this.setState({ ...INITIAL_STATE });
            });
    };

    onChange = event => {
        this.setState( { [event.target.name]: event.target.value });
    };

    render() {
        const {email, error } =  this.state;
        const isInvalid =  email === '';

        return (
            <div className="container">
            <div className="row">
                <div className="col col-10 mx-auto">
                    {error && <div className="alert alert-danger">{error.message}</div>}
                </div>
                <div className="col col-10 mx-auto">
                    <form className="form" onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <input name="email" value={this.state.email} onChange={this.onChange}
                                className="form-control" type="text" placeholder="Email"/>
                        </div>
                        <button className="btn btn-lg btn-primary btn-block" 
                                disabled={isInvalid} type="submit">Resetar a Senha</button>
                    </form>
                </div>
            </div>
            </div>
        )
    }
}

const PasswordForgetLink = () => (
    <p>
        <Link to={ROUTES.PASSWORD_FORGET}>Esqueceu a senha?</Link>
    </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };