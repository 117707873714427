import React, { Component, Fragment } from 'react';
import Chat from './Chat';

class ChatModal extends Component {

	static show(uid) {
        console.log(ChatModal);
		ChatModal.__singletonRef.__show(uid);
	}

	static hide() {
        ChatModal.__singletonRef.__hide();
    }

	constructor(props) {
		super(props);
        ChatModal.__singletonRef = this;
        this.state = {
            uid:null,
        }

	}

	__show(uid) { 
        this.setState({uid:uid})
        window.$('#fsChatModal').modal('show');
     }
	__hide() {
        window.$('#fsChatModal').modal('hide');
    }

    render () {
        return (
            <Fragment>
            <div id="fsChatModal"
                className="modal animated bounceIn"
                tabindex="-1"
                style={{paddingRight:'0px !important'}}
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <img src="images/logo-mercate.png" style={{height:'28px'}} alt="Mercate"/>
                    <span id="myModalLabel" className="modal-title">Chat</span>
                    <span onClick={this.__hide} className="close-icon">
                    <i class="far fa-times-circle fa-2x"></i>
                    </span>
                </div>
                <div className="modal-body">
                    { this.state.uid && <Chat uid={this.state.uid}/> }
                </div>
    
                </div>
    
            </div>
            </div>
        </Fragment>
        )
    
    }
}


export default ChatModal;