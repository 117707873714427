export const ERROR_CODE_ACCOUNT_EXISTS =
'auth/account-exists-with-different-credential';

export const ERROR_MSG_ACCOUNT_EXISTS = `
An account with an E-Mail address to
this social account already exists. Try to login from
this account instead and associate your social accounts on
your personal account page.
`;

export const ERROR_CODE_EMAIL_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

export const ERROR_MSG_EMAIL_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;