import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import config from '../../config/firebase';
import appConfig from '../../config';
import localRemoteConfig from '../../config/localRemoteConfig'

  class Firebase {
    constructor() {
          app.initializeApp(config);

          /* Helper */
          this.serverValue = app.database.ServerValue;

          this.emailAuthProvider = app.auth.EmailAuthProvider;
          this.auth   = app.auth();
          this.db     = app.database();
          this.storage= app.storage();
          if (appConfig.useRemoteConfig)
            this.remoteConfig = app.remoteConfig();
          else this.remoteConfig = localRemoteConfig;
          // Intervalo de atualizacao da configuracao remota
          this.remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

          this.googleProvider = new app.auth.GoogleAuthProvider();
          this.facebookProvider = new app.auth.FacebookAuthProvider();
          this.twitterProvider = new app.auth.TwitterAuthProvider();
    }

    defaultConfig = {
        "app-name" : "Mercate",
        "layout"  : "PAPER",    // PAPER|SIMPLE
    } 

      // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email,password);
    
    doSignInWithEmailAndPassword = (email,password) =>
        this.auth.signInWithEmailAndPassword(email,password);

    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);

    doSignInWithFacebook = () =>
        this.auth.signInWithPopup(this.facebookProvider);

    doSignInWithTwitter = () =>
        this.auth.signInWithPopup(this.twitterProvider);
        
    doSignOut = () => this.auth.signOut();
    
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPassswordUpdate = password => this.auth.currentUser.updatePassword(password);
    
    doSendEmailVerification = () =>
      this.auth.currentUser.sendEmailVerification({
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
      })

    // *** Sequence ***
    
    nextVal = async (sequence) => {
      const seq= await this.db.ref('counter').child(sequence).transaction(function(currentValue) {
        const newVal= (currentValue||0) + 1;
        return newVal;
      });
      return seq.snapshot.val();
    }


    
    // *** User API *** 
    user = uid => this.db.ref(`users/${uid}`);

    users = () => this.db.ref('users');

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) =>
      this.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          this.user(authUser.uid)
            .once('value')
            .then(snapshot => {
              const dbUser = snapshot.val();
              // Default empty roles
              if (!dbUser.roles) {
                dbUser.roles = {};
              }

              // Merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              }

              next(authUser);
            });
        } else {
          fallback();
        };
      });

      advertisementType  = (uid)  => this.db.ref(`advertisementType/${uid}`);
      advertisementTypes = ()    => this.db.ref('advertisementType');

      // Dominio multi tenant
      // *** Message API *** Teste de tenant

      message = (authUser,uid) => this.db.ref(`sellers/${authUser.uid}/messages/${uid}`);

      messages =  (authUser) => this.db.ref(`sellers/${authUser.uid}/messages`);

      // *** Product API ***
      product   = (authUser,uid)  => this.db.ref(`sellers/${authUser.uid}/products/${uid}`);
      products  = (authUser)      => this.db.ref(`sellers/${authUser.uid}/products`);

      // *** Advertisement API ***
      advertisement   = (authUser,uid)  => this.db.ref(`sellers/${authUser.uid}/advertisements/${uid}`);
      advertisements  = (authUser)      => this.db.ref(`sellers/${authUser.uid}/advertisements`);

      // *** Order API ***
      order   = (authUser, uid)   => this.db.ref(`sellers/${authUser.uid}/orders/${uid}`);
      orders  = (authUser)        => this.db.ref(`sellers/${authUser.uid}/orders`);
      // ** Chat API **
      chat = (uid) => this.db.ref(`chats/${uid}`);
      chats = () => this.db.ref('chats');
      
      ///////////////////////////////////////

      assembleOrder =  async (authUser, uid) => {
        const snapshot =  await this.order(authUser,uid).once('value');
        if (snapshot.val()) {
          const order = await this.completeOrder({uid:uid, ...snapshot.val()});
          return order;
        } else return null;
      }
      listenOrder =  async (authUser, uid) => {
        return await this.order(authUser,uid).on('value',async (snapshot)=> {
          if (snapshot.val()) {
            const order = await this.completeOrder({uid:uid, ...snapshot.val()});
            return order;
          } else return null;
        });
      }
      completeOrder = async (data) => {
        const authUser=await this.User.get(data.accountId)
        const order =  {...data, authUser: authUser}
                  
        if (data.advertisementId) {
          const advertisement = await this.Advertisement.get(authUser,data.advertisementId);
          order.advertisement = advertisement; 
        }
        await Promise.all(order.items.map(async (item)=> {
          if (item.productId && item.productId!=='DELIVERY_FEE' && !item.product) {
              const product =  await this.Product.get(authUser,item.productId);
              item.product = product;
          } else if (item.productId && item.productId==='DELIVERY_FEE' && !item.product) {
              const product =  {uid:'DELIVERY_FEE',name:'Taxa de entrega', price:item.price}
              item.product = product;
          }
          return item;
        }))
        return order
      }

      cancelOrder = (order) => {
        return this.order(order.authUser,order.uid).update({
          status:'CANCELED',
        })
      }



      assembleProduct = async (authUser, uid) => {
        const snapshot = await this.product(authUser,uid).once('value');
        const prod ={uid:uid, ...snapshot.val()};
        if (prod.images) prod.images.forEach( (i,idx) => {
            i.key=String(idx);
        })
        console.log(prod);
        return prod; 
      }

      assembleUser = async (uid) => {
        const snapshot = await this.user(uid).once('value');
        const user ={uid:uid, ...snapshot.val()};
        return user;
      }

      assembleAdvertisement = async (authUser, uid) => {
        const snapshot = await this.advertisement(authUser,uid).once('value');
        const ad ={uid:uid, ...snapshot.val()};
        if (ad.productId) {
          ad.product=this.assembleProduct(authUser,ad.productId);
        }
        if (ad.productIds) {
          ad.products=ad.productIds.map((uid)=> this.assembleProduct(authUser,uid))
        }
        return ad; 
      };

      assembleAdvertisementList = async (authUser, uid) => {
        await this.advertisements(authUser)
          .orderByChild('createdAt')
          .once('value', snapshot => {
              const advertisementObject = snapshot.val();

              if (advertisementObject) {
                  const advertisementList = Object.keys(advertisementObject).map( key => ({
                      ...advertisementObject[key],
                      uid: key,
                  }));
                  const ads=advertisementList.map((ad) => {
                      const adAssembled={...ad };
                      if (ad.type==='SINGLE' && ad.productId) adAssembled.product=this.assembleProduct(authUser,ad.productId);
                      if (ad.type==='LIST' && ad.productIds)  adAssembled.products=ad.productIds.map((uid)=> {return this.assembleProduct(authUser,uid)});
                      return adAssembled;
                  })
                  return ads;
              } else {
                  return [];
              }
            })
      };

      advertisementFindAllByProduct = async (authUser,uuid) => {
        const ref=this.advertisements(authUser)
        const snapshot = await ref.orderByChild("productId").equalTo(uuid).once('value')
        const ads=snapshot.val();

        const result = Object.keys(ads).map((key)=>{
            const row={uuid:key, ...ads[key]};
            return row;
        });
        return result;
      }
      advertisementFindAllByType = async (authUser,type) => {
        const ref=this.advertisements(authUser)
        const snapshot = await ref.orderByChild("type").equalTo(type).once('value')
        const ads=snapshot.val();

        const result = Object.keys(ads).map((key)=>{
            const row={uuid:key, ...ads[key]};
            return row;
        });
        return result;
      }
      advertisementFindByProduct = async (authUser,uuid) => {
        const ref=this.advertisements(authUser)
        const snapshot = await ref.orderByChild("productId").equalTo(uuid).limitToFirst(1).once('value')
        const ads=snapshot.val();
        let ad;
        const result = Object.keys(ads).forEach((key)=>{
            ad = {uuid:key, ...ads[key]};
        });
        return ad;
      }
      ////////////////////////////////////////////////////////////
      addMessageChat = (uid,type, message) => {
          this.getChat(uid).then((chat)=> {
              const msg={sender:type, message:message, createdAt:this.serverValue.TIMESTAMP};

              const messages = chat.messages ? [ ...chat.messages, msg] : [msg];
              const nchat={uid:uid, messages:messages}
              this.chat(uid).update(nchat).then (()=>{
                  return messages;
              })
          })
      }
      getChat = async (uid) => {
        const snapshot = await this.chat(uid).once('value');
        let chat;
        if (snapshot) {
          chat={uid:uid, ...snapshot.val()};
        } else {
          chat={uid:uid, messages:[]}
        }
        return chat;
      }
      /////////////////////////////////////////////////////////////
      Advertisement = {
        get: (authUser, uid) => this.assembleAdvertisement(authUser,uid),
        save: async (authUser, ad) => { await this.advertisements(authUser).push(ad) },
        findAllByProductId: async (authUser, uuid) => {return await this.advertisementFindAllByProduct(authUser,uuid)},
        findAllByType: async (authUser, type) => {return await this.advertisementFindAllByType(authUser,type)},
        findByProductId: async (authUser, uuid) => {return await this.advertisementFindByProduct(authUser,uuid)},
      };

      Product = {
        get:  (authUser, uid) => this.assembleProduct(authUser,uid),
        save: async (authUser, ad) => {  await this.products(authUser).push(ad) },
      }
      User = {
        get: (uid) => this.assembleUser(uid),
      }
      Order = {
        get: (authUser, uid) => this.assembleOrder(authUser,uid),
        listen: (authUser, uid) => this.listenOrder(authUser,uid),
        completeOrder : (data) => this.completeOrder(data),
        cancel: (order) => this.cancelOrder(order),

      };
      Chat = {
        get: (uid) => this.getChat(uid),
        addMessage: (uid, type, message) => this.addMessageChat(uid,type, message),        
      }
      /**
       * Storage API
       */
      uploadImageSync = async (authUser, file) => {
        const name= file.name ? `${file.name}_${Date.now()}` : `IMAGE_${Date.now()}`  
        let storageRef = this.storage.ref(authUser.uid)
        await storageRef.child(name).put(file);
      }

      uploadImage = (authUser, file, onStateChange) => {
        const stor = this.storage;
        return new Promise(function (resolve, reject) {
          const name= file.name ? `${file.name}_${Date.now()}` : `IMAGE_${Date.now()}`  
          let storageRef = stor.ref(authUser.uid)
          const task = storageRef.child(name).put(file);

          task.on('state_changed', function (snapshot) {
              var p = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + p + '% done');
              onStateChange(p)
              switch (snapshot.state) {
                  case 'paused': // or 'paused'
                      console.log('Upload is paused');
                      break;
                  case 'running': // or 'running'
                      console.log('Upload is running');
                      break;
                  default:
                      console.log(`${snapshot.state} ...`);
                      break;
              }
          }, function (error) {
                  reject(error);
          }, function () {
              task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                  onStateChange(0);
                  resolve(downloadURL);
              });
          });
  
      })    

      }

      /*
        Tiny URL API 
      */

     tinyUrl   = (uid)  => this.db.ref(`tinyUrl/${uid}`);
     addTinyUrl = async (uid,data) => {
        console.log(uid);
        console.log(data);
        await this.tinyUrl(uid).push(data)
     }

     existsTinyUrl = async (uid) => {
      const snapshot = await this.tinyUrl(uid).once('value');
      const url ={...snapshot.val()};
      console.log(url);
      return (Object.keys(url).length === 0) ? false : true;
     }


     addTinyUrlIfNotExists = async (uid, data) => {
       this.existsTinyUrl(uid).then((exists) => {
          if (!exists) {
            this.addTinyUrl(uid,data)
          }
       })
     }

     // Statistics

     countProducts =async (authUser) => {
        const snapshot = await this.products(authUser).once('value');
        let count=0
        if (snapshot.val())
          count =Object.keys(snapshot.val()).length;
        return count;
      }
      countAdvertisements =async (authUser) => {
        const snapshot = await this.advertisements(authUser).once('value');
        let count=0
        if (snapshot.val())
          count =Object.keys(snapshot.val()).length;
        return count;
      }
      countOrders =async (authUser) => {
        const snapshot = await this.orders(authUser).once('value');
        let count=0
        if (snapshot.val())
          count =Object.keys(snapshot.val()).length;
        return count;
      }
    }

  export default Firebase;