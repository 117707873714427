import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from './SignUp';
import { PasswordForgetLink } from "./PasswordForget";
import { withFirebase } from '../Firebase';

import SignInGoogle from './SignInGoogle';
import SignInFacebook from './SignInFacebook';
import SignInTwitter from './SignInTwitter';
import * as ROUTES from '../../config/routes';
import config from '../../config';

const SignInPage = () => (
    <div className="full-height-container" style={{backgroundColor:'#FFFFFF'}}>
        <div className="container both-center">
        <div className="row">
            <div className="col-12 col-sm-12 col-md-6 my-auto border-right">
                <img src="/images/signin.png" className="img-fluid my-auto" alt="..."/>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
                    <div className="row">
                        <div className="col col-10 mx-auto card-title text-center">
                            <h3 clasName="font-weight-bold">Entrar</h3>
                        </div>
                    </div>
                    <SignInForm />
                    <div className="row">
                        <div className="col col-10 mx-auto card-title">
                            <PasswordForgetLink />
                            {config.version==='beta' ?
                            <p>
                                Não possui uma conta? <a href="https://www.mercate.com.br/signup">Registre-se</a>
                            </p>
                            :
                            <p>
                            <SignUpLink />
                            </p>

}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-10 mx-auto card-title text-center">
                            <h3 className="font-weight-bold">ou entre com</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-10 mx-auto card-title">
                        {config.login.google && <SignInGoogle/>}
                        {config.login.facebook && <SignInFacebook/>}
                        {config.login.twitter && <SignInTwitter/>}
                        </div>
                    </div>
            </div>
        </div>
        </div>


    </div>


);

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();
        const { email, password } = this.state;
        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(()=>{
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME);
            })
            .catch( error => {
                this.setState({ error });
            });
    };

    onChange = event => {
        this.setState( { [event.target.name]: event.target.value });
    };

    render() {
        const {email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <div className="row">
                <div className="col col-10 mx-auto">
                    {error && <div className="alert alert-danger">{error.message}</div>}
                </div>
                <div className="col col-10 mx-auto">
                    <form className="form" onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <input name="email" value={email} placeholder="Email" className="form-control"
                                onChange={this.onChange} type="text" />
                        </div>
                        <div className="form-group">
                            <input name="password" value={password} placeholder="Senha" className="form-control"
                                onChange={this.onChange} type="password" />
                        </div>
                        <button className="btn btn-lg btn-primary btn-block" 
                            disabled={isInvalid} type="submit">Entrar</button>
                    </form>
                </div>
            </div>
        );
    };
}
const SignInForm = compose (
    withRouter,
    withFirebase) (SignInFormBase);

export default SignInPage;
export { SignInForm, SignInGoogle, SignInFacebook };    

