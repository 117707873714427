import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const needsEmailVerification = authUser => 
    authUser &&
    !authUser.emailVerified &&
    authUser.providerData
        .map(provider => provider.providerId)
        .includes('password');

const withEmailVerification =  Component => {
        class WithEmailVerification extends React.Component {
            constructor(props) {
                super(props);
                this.state = { isSent: false };
            }
            onSendEmailVerification = () => {
                this.props.firebase
                    .doSendEmailVerification()
                    .then(() => this.setState({isSent:true}));
            };
            render () {
                return (
                    <AuthUserContext.Consumer>
                        {authUser => 
                            needsEmailVerification(authUser) ? (
                            <div className="container mt-4">
                                <div className="row">
                                    <div className="col col-10 mx-auto">
                                        <h3>Email de Confirmação enviado</h3>
                                    </div>
                                    <div className="col col-10 mx-auto">
                                    { this.state.isSent ? (
                                        <p>Verifique seu email: procure nos seus emails (incluindo na pasta de span) 
                                        por um email de confirmação. Atualize esta pagina apos a confirmação.
                                        </p>

                                        ) : (
                                        <p>Verifique seu email: procure nos seus emails (incluindo na pasta de span) 
                                            por um email de confirmação ou solicite um novo email de confirmação.
                                        </p>
    
                                        )
                                    }
                                    </div>
                                    <div className="col col-10 mx-auto text-center">
                                    <button type="button" className="btn btn-lg btn-primary" 
                                            onClick={this.onSendEmailVerification}
                                            disabled={this.state.isSent}
                                            >Reenviar email de confirmação</button>
                                    </div>
                                </div>
                            </div>
                            ) : (
                                <Component { ...this.props } /> 
                            )
                    }
                    </AuthUserContext.Consumer>
                )
            }
        }
        return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
