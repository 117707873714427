import React, { Component }  from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import { compose } from 'recompose';

import * as ROUTES from '../../config/routes';
import * as ROLES from '../../config/roles';
import * as ERRORS from '../../config/errors';

const SignUpPage = () => (
    <div className="full-height-container" style={{backgroundColor:'#FFFFFF'}}>
    <div className="container both-center">
        <div className="text-center pb-4">
            <span>
            <img src="/images/logo-mercate.png" className="img-fluid my-auto" alt="..." style={{height:'80px'}}/> 
            </span>
        </div>
        <div className="row">
            <div className="col col-10 mx-auto card-title text-center">
                <h3>Cadastre-se</h3>
            </div>
        </div>
        <SignUpForm /> 
    </div>
    </div>
)

const INITIAL_STATE = {
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    isAdmin: false,
    error: null,
};

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    }

    onSubmit = event => {
        const { username, email, passwordOne, isAdmin } = this.state;
        const roles = {};
        if (isAdmin) {
            roles[ROLES.ADMIN] =  ROLES.ADMIN;
        }
        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                return this.props.firebase
                    .user(authUser.user.uid)
                    .set({
                        username, 
                        email,
                        roles,
                    });
            })
            .then(()=> {
                return this.props.firebase.doSendEmailVerification();
            })
            .then(authUser => {
                this.setState({...INITIAL_STATE});
                this.props.history.push(ROUTES.HOME);
            })
            .catch(error=>{
                if (error.code === ERRORS.ERROR_CODE_ACCOUNT_EXISTS) {
                    error.message = ERRORS.ERROR_MSG_ACCOUNT_EXISTS;
                }
                this.setState({ error });
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render() {
        const {
            username,
            email,
            passwordOne,
            passwordTwo,
            isAdmin,
            error,
        } = this.state;

        const isInvalid = 
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            username === '';

        return (

            <div className="row">
                <div className="col col-10 mx-auto">
                    {error && <div className="alert alert-danger">{error.message}</div>}
                </div>
                <div className="col col-10 mx-auto">
                    <form className="form" onSubmit={this.onSubmit}>
                        <div className="form-group">
                        <input  name="username" type="text" placeholder="Seu nome" className="form-control"
                                value={username} onChange={this.onChange}/>
                        </div>
                        <div className="form-group">
                        <input  name="email" type="text" placeholder="Seu email" className="form-control"
                                value={email} onChange={this.onChange}/>
                        </div>
                        <div className="form-group">
                        <input  name="passwordOne" type="password" placeholder="Senha" className="form-control"
                                value={passwordOne} onChange={this.onChange}/>
                        </div>
                        <div className="form-group">
                        <input  name="passwordTwo" type="password" placeholder="Confirme a senha" className="form-control"
                                value={passwordTwo} onChange={this.onChange}/>
                        </div>
                        <div className="form-group pl-4">
                            <input name="isAdmin" type="checkbox" className="form-check-input" id="isAdminCheck" 
                                    checked={isAdmin} onChange={this.onChangeCheckbox} />
                            <label className="form-check-label" for="isAdminCheck">Administrador</label>
                        </div>
                        <button className="btn btn-lg btn-primary btn-block" type="submit" disabled={isInvalid} >Registrar</button>

                    </form>
                </div>
            </div>
        )
    }
}

const SignUpLink = () => (
    <p>
        Não possui uma conta? <Link to={ROUTES.SIGN_UP}>Registre-se</Link>
    </p>
);


const SignUpForm = compose(
    withRouter,
    withFirebase
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };