import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../config/routes';

class SignInTwitterBase extends Component {
    constructor(props) {
        super(props);
        this.state =  { error: null }
    }

    onSubmit = event => {
        event.preventDefault();

        this.props.firebase
            .doSignInWithTwitter()
            .then( socialAuthUser => {
                // Create a user in Firebase Realtume Database
                return this.props.firebase 
                    .user(socialAuthUser.user.uid)
                    .set( {
                        username: socialAuthUser.additionalUserInfo.profile.name,
                        email: socialAuthUser.additionalUserInfo.profile.email,
                        roles: {},
                    });
            })
            .then(() => {
                this.setState( { error: null } );
                this.props.history.push(ROUTES.HOME);
            })
            .catch( error => {
                this.setState({ error });
            });
    };

    render() {
        const { error } = this.state;
        return (
            <div className="row">
            <div className="col mx-auto  my-1">
            <form className="form" onSubmit={this.onSubmit}>
                <button className="btn btn-md btn-outline-primary btn-block"  type="submit">
                    <i className="fab fa-twitter pr-4"></i>
                    Twitter
                </button>
                {error && <p>{ error.message }</p> }
            </form>
            </div>
            </div>
        );
    }
}

const SignInTwitter =  compose(
    withRouter,
    withFirebase,
) (SignInTwitterBase);

export default SignInTwitter;