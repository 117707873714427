import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../config/routes';
import * as ERRORS from '../../config/errors';

class SignInGoogleBase extends Component {
    constructor(props) {
        super(props);
        this.state =  { error: null }
    }

    onSubmit = event => {
        event.preventDefault();

        this.props.firebase
            .doSignInWithGoogle()
            .then( socialAuthUser => {
                // Create a user in Firebase Realtume Database
                return this.props.firebase 
                    .user(socialAuthUser.user.uid)
                    .set( {
                        username: socialAuthUser.user.displayName,
                        email: socialAuthUser.user.email,
                        roles: {},
                    });
            })
            .then(() => {
                this.setState( { error: null } );
                this.props.history.push(ROUTES.HOME);
            })
            .catch( error => {
                if (error.code === ERRORS.ERROR_MSG_ACCOUNT_EXISTS) {
                    error.message = ERRORS.ERROR_MSG_ACCOUNT_EXISTS;
                }
                this.setState({ error });
            });
    };

    render() {
        const { error } = this.state;
        return (
            <div className="row">

            <div className="col mx-auto my-1">
            <form className="form" onSubmit={this.onSubmit}>
                <button className="btn btn-md btn-outline-primary btn-block"  
                    type="submit">
                        <i className="fab fa-google pr-4"></i>
                        Google</button>
                {error && <p>{ error.message }</p> }
            </form>
            </div>
            </div>
        );
    }
}

const SignInGoogle =  compose(
    withRouter,
    withFirebase,
) (SignInGoogleBase);

export default SignInGoogle;