import React, {useContext, useState, useEffect, useRef }from 'react';
import { Fragment } from 'react';
import { FirebaseContext } from '../Firebase';
import './chat.css';



const Timestamp = ({timestamp}) => {
    const formatedDateTime = new Intl.DateTimeFormat('bt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(timestamp);
    return (
        <span className="date-time">{formatedDateTime}</span>
    )
} 
const Chat = ({uid}) => {
    const [chat, setChat] = useState(null);
    const [message, setMessage] = useState('');
    const firebase = useContext(FirebaseContext);
    const messagesEndRef = useRef(null);

    useEffect( ()=>{
        firebase.chat(uid).on('value',async ( snapshot ) => {
                const chatObject = snapshot.val();
                if (chatObject) {
                    const chatInstance = {
                        ...chatObject,
                        uid:uid,
                    };
                    console.log(chatInstance);
                    setChat(chatInstance);
                }
            })
    },[firebase,uid]);

    useEffect( ()=>{
        scrollToBottom();
    },[chat]);

    const sendMessage=(message) => {
        firebase.Chat.addMessage(uid, 'seller',message);
        setMessage('');
    }
    const scrollToBottom = () => {
        var list = document.getElementById("chat");
        console.log(list);
        if(list) list.scrollTop = list.scrollHeight;
    }
    return (
        <Fragment>
        { chat &&
        <Fragment>
            <div className="chat-wrapper">

                <div className="content chat" id="chat">
                    {chat.messages ?
                    <Fragment>
                        {chat.messages.map((msg) => 
                            <Fragment>
                            <div className="clearfix"></div>
                            { (msg.sender==='mercate') && 
                                <div className="speech-bubble speech-center bg-warning">
                                    {msg.message}
                                </div>    
                            }  

                            { (msg.sender==='seller') && 
                                <div className="speech-bubble speech-left client">
                                    {msg.message}
                                    {msg.createdAt && <span className="speech-read mb-0">
                                                            <Timestamp timestamp={msg.createdAt}/>
                                                    </span>}
                                </div>    
                            }  
                            { (msg.sender==='client') && 
                                <div className="speech-bubble speech-right color-white">
                                    {msg.message}
                                    {msg.createdAt && <span className="speech-read mb-0">
                                                        <Timestamp timestamp={msg.createdAt}/>
                                                        </span>}
                                </div>    
                            }
                            </Fragment>
                        )}                
                    </Fragment>
                    : 
                        <Fragment>
                        <div className="clearfix"></div>
                        <div className="speech-bubble speech-center bg-highlight">
                            Use esse chat para se comunicar com o vendedor
                        </div>    
                        </Fragment>
                    }
                    <div ref={messagesEndRef} />
            </div>


            <div id="footer-bar"  className="d-flex">
                <div className="mr-3 speach-icon">
                    <a href="#" data-menu="menu-upload" className="bg-gray2-dark ml-2"><i className="fa fa-plus"></i></a>
                </div>
                <div className="flex-fill speach-input">
                    <input type="text" className="form-control" placeholder="Digite sua mensagem aqui"
                        value={message} onChange={(e)=> setMessage(e.target.value)}/>
                </div>
                <div className="ml-3 speach-icon">
                    <button className="btn btn-secondary mr-2 mt-2 send-button"
                            onClick={()=> sendMessage(message)}><i className="fa fa-arrow-right"></i></button>
                </div>
            </div>

            </div>
        </Fragment>
        }
        </Fragment>
    )
}

export default Chat;
