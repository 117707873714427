import React from 'react';
import './App.css';

import {
  HashRouter,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import SignUpPage from './Auth/SignUp';
import SignInPage from './Auth/SignIn';
import PasswordForgetPage from './Auth/PasswordForget';
import  { withAuthentication } from './Session'
import { CookieBanner } from 'react-cookie-law-customizable';
import ChatModal from './Chat';
import { Fragment } from 'react';
import * as ROUTES from '../config/routes';
import EmptyLayout from './layouts/EmptyLayout';
import '../scss/style.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('../containers/TheLayout'));
const App = () => {
  
  return (
    <Fragment>
      <ChatModal/>
      <HashRouter>
          <React.Suspense fallback={loading}>
          <Switch>
            <Route path={ROUTES.SIGN_UP}>
              <SignUpPage />
            </Route>
            <Route path={ROUTES.SIGN_IN}>
              <SignInPage />
            </Route>
            <Route path={ROUTES.PASSWORD_FORGET}>
              <PasswordForgetPage />
            </Route>
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
          </Switch>
        </React.Suspense>
        <CookieBanner
        message="Nossa plataforma utiliza cookies para garantir que você tenha a melhor experiência. Se quiser saber mais, basta acessar nossa politica de privacidade"
        wholeDomain={true}
        onAccept = {() => {}}
        onAcceptPreferences = {() => {}}
        onAcceptStatistics = {() => {}}
        onAcceptMarketing = {() => {}}
        acceptButtonText={(<span><i class="far fa-check mr-2"></i>Concordo</span>)}
        privacyPolicyLinkText="Politica de Privacidade"
        necessaryOptionText="Necessario"
        statisticsOptionText="Estastisticas"
        marketingOptionText="Marketing"
        preferencesOptionText="Preferencias"
        styles ={{
          button: {
            backgroundColor: '#52cbcd',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '6px',
            color: 'white',
            fontWeight: 'bold',
          },
          dialog: {
            position: 'fixed',
            bottom: '0px',
            left: '0px',
            right: '0px',
            zIndex: '100000',
            backgroundColor: 'rgb(248, 247, 247)',
            padding: '10px',
            paddingBottom:'20px'
          }
        }}
      />
      </HashRouter>
    </Fragment>
  );
}

export default withAuthentication(App);
