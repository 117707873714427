import config from './index';

class valueObject {
    constructor (v) {
        this.value=v;
    } 
    asString = () => {
        if (this.value)
            return String(this.value);
        else return null;
    };
    asJson = () => {
        if (this.value)
            return this.value;
        else return {};
    };
}

class localRemoteConfig {
    constructor() {
        this.settings = {
            minimumFetchIntervalMillis:3600000
        }
        this.config = {
            layout:'PAPER',
            app_name:'Mercate'
        }
        this.defaultConfig = config.remoteConfig;
    }
    fetchAndActivate = () => {
        return true;
    }
    getValue = (name) => {
        let value = this.config[name];
        if (!value) value=this.defaultConfig[name];
        
        const v = new valueObject(value);
        return v;
    }

}

export default new localRemoteConfig();