var development = {
    apiKey: "AIzaSyCRr_XfQIfvpXPw3KG4m2oKUHfj88vV3JA",
    authDomain: "mercate-e103f.firebaseapp.com",
    databaseURL: "https://mercate-e103f.firebaseio.com",
    projectId: "mercate-e103f",
    storageBucket: "mercate-e103f.appspot.com",
    messagingSenderId: "1059868211656",
    appId: "1:1059868211656:web:1726436827de37d5cca8fd",
    measurementId: "G-M03L1Q669L"
  };

  var production = {
    apiKey: "AIzaSyCRr_XfQIfvpXPw3KG4m2oKUHfj88vV3JA",
    authDomain: "mercate-e103f.firebaseapp.com",
    databaseURL: "https://mercate-e103f.firebaseio.com",
    projectId: "mercate-e103f",
    storageBucket: "mercate-e103f.appspot.com",
    messagingSenderId: "1059868211656",
    appId: "1:1059868211656:web:1726436827de37d5cca8fd",
    measurementId: "G-M03L1Q669L"
  };

  const config = process.env.REACT_APP_STAGE === 'production'
  ? production
  : development;
  
  export default {
    ...config
  };

