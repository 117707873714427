import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../Firebase';
import ChatModal from './ChatModal'
import './chat.css';

const ChatButton = ({uid}) => {
    const firebase = useContext(FirebaseContext);
    const [chat, setChat] = useState(null);
    const [unread , setUnread] = useState(0);
    useEffect( ()=>{
        firebase.chat(uid).on('value',async ( snapshot ) => {
                const chatObject = snapshot.val();
                if (chatObject) {
                    const chatInstance = {
                        ...chatObject,
                        uid:uid,
                    };
                    console.log(chatInstance);
                    setChat(chatInstance);
                }
            })
    },[firebase,uid]);

    useEffect(()=> {
        let noread=0;
        if (chat)
            chat.messages.forEach((msg)=>{
                if (msg.sender==='client' && !msg.read) noread++; 
            });
        setUnread(noread);
    },[chat])

    const openChat = () => {
        if (uid)
            ChatModal.show(uid)
    }

    return (
        <div className="chat-button" onClick={openChat}>
            <i className={`far fa-comment-dots ${unread ? ' has-unread': ''}`}></i>
            {(unread>0)  && <div className="unread">{unread}</div>}
        </div>
    );
}


export default ChatButton;