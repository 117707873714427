export const START = '/';
export const SIGN_OUT = '/signout';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET ='/pw-forget';
export const CHANGE_PASSWORD ='/changePassword';
export const ABOUT = '/about';
export const ADMIN = '/admin';
export const ADMIN_DETAIL = '/admin/:id';
export const PRODUCTS = '/products';
export const ADS = '/ads';
export const ORDERS = '/orders';
export const TESTS = '/tests';
