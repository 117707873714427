const production = {
    urlBase:'https://irpra.net',
    login: {
        google:true,
        facebook:false,
        twitter:false,
    },
    remoteConfig: {
        footer: { 
            mercate:true, 
            credits: {
                copyright:false
            },
            social:true,
            contact: true,            
        }
    },
    version:'beta',
    googleMapsAPI: 'AIzaSyDUEEgI3jsy5xpGGGtEdHgph6_VY98jB7E',
};

const development = {
    urlBase:'http://localhost:3001', 
    login: {
        google:true,
        facebook:true,
        twitter:true,
    },
    remoteConfig: {
        footer: { 
            mercate:true, 
            credits: {
                copyright:true
            },
            social:true,
            contact: true,            
        }
    },
    googleMapsAPI: 'AIzaSyDUEEgI3jsy5xpGGGtEdHgph6_VY98jB7E',
}

const config = process.env.REACT_ENV === 'development'
? development
: production;
export default {
  ...config,
  useRemoteConfig: false,
};